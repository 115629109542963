import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt } from "@fortawesome/free-solid-svg-icons"

const Social = props => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
      setIsMounted(true)
    }, [])

    return (
      <div className={classNames([
        'social bg-dark',
        props.className
      ])}>
        <button type="button" className="btn p-2" data-toggle="modal" data-target="#socialModal" title="Partager sur les réseaux sociaux">
          {isMounted && <FontAwesomeIcon icon={faShareAlt} className="icon text-white" size="2x" />}
        </button>
      </div>
    )
  }

  export default Social;