import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons"
import { Facebook, Twitter } from "react-social-sharing"
import YouTube from 'react-youtube'
import Social from './socialButton'
import Portal from '../../components/global/portal'

const Banner = ({ profession, profileId, titleFirstLine, titleSecondLine, videoId }) => {
  const data = getData(profileId)
  const profiles = data.allProfilesJson.edges
  const { siteMetadata } = data.site

  return (
    <section className="container-fluid hero-section">
      <div className="row">
        <div className="col-12 px-0">
          <div id="carouselBanner" className="carousel slide w-100 vh-100 position-relative">
            <div className="carousel-inner h-100">
              {profiles.map(({node}, index) => (
                <Slide active={index === 0}
                  image={node.image_video}
                  key={index} name={node.name}
                  profession={profession}
                  titleFirstLine={titleFirstLine}
                  titleSecondLine={titleSecondLine}
                  videoId={videoId ? videoId : node.video}
                />
              ))}
              <div className="d-none d-md-block">
                  <Social className='social-button' />
              </div>

              {profession && <BannerTitle firstLine={titleFirstLine} profession={profession} secondLine={titleSecondLine} /> }
            </div>
          </div>
        </div>
      </div>
      <SocialModal slug={profileId} url={siteMetadata.siteUrl} />
    </section>
  )
}

const BannerTitle = ({firstLine, profession, secondLine}) => (
  <div className="profile-title">
    <p className="title title-1 display-2 d-inline-block font-weight-bold bg-primary px-3 text-uppercase text-nowrap">
      {firstLine}
    </p>
    <div></div>
    <p className="title title-1 display-2 d-inline-block font-weight-bold bg-primary px-3 text-uppercase text-nowrap">
      {secondLine}
    </p>
    {profession ? (
      <Profession profession={profession} />
    ) : ''}
  </div>
)

const PlayButton = ({videoId}) => {
  const [play, setPlay] = useState(false);
  useEffect(() => {
    setPlay(true)
  });
  return (
    <div className="play-button d-block text-center w-50">
      <div className="row">
        <div className="col-12">
          {play && (
            <button type="button" className="btn p-2 text-white" data-toggle="modal" data-target={`#modal-${videoId}`} title="Lecture du vidéo">
              <FontAwesomeIcon icon={faPlayCircle} size="5x" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

const Profession = ({profession}) => {
  return (
    <div className="profession">
      <h5 className="d-inline bg-white font-weight-bold px-2 py-1">
        {profession}
      </h5>
    </div>
  )
}

const Slide = ({active, image, name, profession, titleFirstLine, titleSecondLine, videoId}) => (
  <div className={`carousel-item h-100 ${active ? `active` : ''}`} data-interval="false">
    <div className="d-block w-100 h-100"
         style={{backgroundImage: `url("${image.childImageSharp.fluid.src}")`, backgroundSize: 'cover', backgroundPositionX: 'center'}}
    >
      <Social className='social-button' />
    </div>
    <PlayButton videoId={videoId}/>
    <VideoModal name={name} videoId={videoId} />
  </div>
)

const SocialModal = ({slug, url}) => (
  <Portal>
    <div className="modal fade video-modal" id="socialModal" tabIndex="-1" role="dialog" aria-labelledby="socialModalLabel"
       aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="socialModalLabel">Partager sur les réseaux sociaux</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Twitter link={`${url}${slug ? slug.substring(1) : ''}`} />
            <Facebook link={`${url}${slug ? slug.substring(1) : ''}`} />
          </div>
        </div>
      </div>
    </div>
  </Portal>
)

const VideoModal = ({name, videoId}) => (
  <Portal>
    <div className="modal fade" id={`modal-${videoId}`} tabIndex="-1" role="dialog" aria-labelledby="socialModalLabel"
       aria-hidden="true">
      <div className="modal-dialog modal-xl bg-dark text-white" role="document">
        <div className="modal-content bg-dark text-white">
          <div className="modal-header border-0">
            <h5 className="modal-title" id="socialModalLabel">{name}</h5>
            <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body videoWrapper">
            <YouTube
              videoId={videoId}
            />
          </div>
        </div>
      </div>
    </div>
  </Portal>
)

const getData = (profileId) => {
  const { allFile, allProfilesJson, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl,
          }
        }
        allProfilesJson(sort: {fields: id, order: ASC}) {
          edges {
            node {
              fields {
                slug 
              },
              image_video,
              name,
              video
            }
          }
        },
        allFile(filter:{ relativePath: { regex: "/^images/profiles/" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid,
                  originalName
                }
              }
            }
          }
        }
      }
    `
  )

  allProfilesJson.edges.map(({node}) => {
    if (typeof node.image_video === 'string') {
      node.image_video = allFile.edges.find((image) => {
        return image.node.childImageSharp.fluid.originalName === node.image_video
      }).node
    }
    return node
  })

  if(profileId) {
    const profile = allProfilesJson.edges.filter(({node}) => {
      return node.fields.slug === profileId
    })
    return {allProfilesJson: { edges: profile }, site}
  }

  return { allProfilesJson, site }
}

Banner.defaultProps = {
  profession: ``,
  profileId: ``,
  titleFirstLine: `Faut le croire`,
  titleSecondLine: `Pour le voir`,
}

Banner.propTypes = {
  profession: PropTypes.string,
  profileId: PropTypes.string,
  titleFirstLine: PropTypes.string,
  titleSecondLine: PropTypes.string,
}

export default Banner
